import { throttle } from '../../utils/throttle/throttle';
import liveUpdate from '../live-update/live-update';

const progresses = [];

const Progress = function (node) {
	let startTime, endTime;
	const progressbar = node.querySelector('.progress__bar');
	const id = node.dataset.channelId;
	const throttledUpdate = throttle(10000, setProgress);

	updateTimeSpans();

	node.setAttribute('data-init', 'true');

	function setProgress() {
		const now = Math.ceil(+new Date() / 1000); // change milliseconds to seconds and no decimals
		let progressValue;

		if (now > endTime) {
			progressValue = 100;
		} else if (now < startTime) {
			progressValue = 1;
		} else {
			progressValue = Math.floor(
				((now - startTime) / (endTime - startTime)) * 100
			);
		}

		node.setAttribute('aria-valuenow', progressValue);
		node.removeAttribute('aria-hidden');
		progressbar.style.setProperty('--_progress', progressValue);

		if (progressValue >= 100) {
			liveUpdate.updateChannel(id);
		}
	}

	function upDateProgress() {
		window.requestAnimationFrame(function () {
			throttledUpdate();
			upDateProgress();
		});
	}

	function updateTimeSpans() {
		startTime = parseInt(node.dataset.starttime, 10); // make it an integer
		endTime = parseInt(node.dataset.endtime, 10); // make it an integer
		setProgress();
	}

	upDateProgress();

	return {
		update: updateTimeSpans,
	};
};

function init() {
	const p = document.querySelectorAll(
		'[js-module~="progress"]:not([data-init])'
	);

	p.forEach((q) => {
		progresses.push(new Progress(q));
	});
}

function update() {
	progresses.forEach((progress) => {
		progress.update();
	});
}

export default {
	init,
	update,
};
