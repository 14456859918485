export const TRAILER_VOLUME_SETTINGS_STORAGE = 'lfvp_trailer_volume';
export function getTrailerVolumeSetting() {
	return window.sessionStorage.getItem(TRAILER_VOLUME_SETTINGS_STORAGE)
		? Number(window.sessionStorage.getItem(TRAILER_VOLUME_SETTINGS_STORAGE))
		: 0;
}

export function setTrailerVolumeSetting(volume) {
	if (typeof volume === 'number') volume.toString();

	window.sessionStorage.setItem(TRAILER_VOLUME_SETTINGS_STORAGE, volume);
}

export function setButtonMutedState(button) {
	button.classList.add('is-muted');
	button.title = window.i18n?.trailer.unmute;
	button.ariaLabel = window.i18n?.trailer.unmute;
}

export function setButtonUnMutedState(button) {
	button.classList.remove('is-muted');
	button.setAttribute('aria-label', window.i18n?.trailer.mute);
	button.title = window.i18n?.trailer.mute;
	button.ariaLabel = window.i18n?.trailer.mute;
}

export function toggleVolumeButtonState() {
	const volumeButtons = document.querySelectorAll(
		'[js-module~="volumeButton"]'
	);
	let volume = getTrailerVolumeSetting() === 0 ? 1 : 0;

	volumeButtons.forEach((button) => {
		if (volume) {
			setButtonUnMutedState(button);
		} else {
			setButtonMutedState(button);
		}
	});

	setTrailerVolumeSetting(volume.toString());
}
