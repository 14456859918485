/* globals popcornPlayer */
import { isMobile } from '../../utils/breakpoints/breakpoints';
import browsersniffing from '../../utils/browsersniffing/browsersniffing';
import { debounce } from '../../utils/throttle/debounce';
import {
	generateItemData,
	generateListData,
} from '../../modules/tracking/tracking.logic';
import {
	getTrailerVolumeSetting,
	setButtonMutedState,
	setButtonUnMutedState,
	toggleVolumeButtonState,
} from '../../modules/trailers/trailers.volume';

export default class top10Video {
	options = {
		videoPlayerClass: '[js-module~="top10Trailer"]',
		isMobile: 'is-mobile',
		card: 'x-top10__card',
		isActive: 'is-active',
		volumeButton: '[js-element~="top10VolumeButton"]',
		isPlaying: 'is-playing',
		isEnded: 'is-ended',
		isPaused: 'is-paused',
		swimlane: '[swimlane-type="top10"]',
		tracking: '[js-element~="tracking"]',
	};

	promises = [
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.Heartbeat),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.GTM),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.CIM),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.Mux),
		popcornPlayer.loadScript(popcornPlayer.ScriptName.GemiusPlayer),
	];

	constructor(element, config = {}) {
		if (!element) throw Error('No top10 element provided');

		this.element = element;
		this.options = { ...this.options, ...config };
		this.delay = 600;

		this.volumeButton = this.element.querySelectorAll(
			this.options.volumeButton
		);

		this.top10Trailers = document.querySelectorAll(
			this.options.videoPlayerClass
		);

		this.mouseEnterTimer = null;
		this.contextData = window.playerConfig;
		this.card = null;

		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.resizeHandler = this.resizeHandler.bind(this);
		this.destroyTop10Trailers = this.destroyTop10Trailers.bind(this);
		this.keydownHandler = this.top10KeydownHandler.bind(this);
		this.firstFocusableElement = null;
		this.lastFocusableElement = null;
		if (browsersniffing.detectMobileOS() || isMobile()) {
			document.documentElement.classList.add(this.options.isMobile);

			throw Error('Mobile device detected');
		}

		this.start();
	}

	async start() {
		window.addEventListener('resize', debounce(this.resizeHandler, 100), {
			passive: true,
		});

		if (this.top10Trailers.length) {
			// destroy all the players and elements on `page leave` so we don't get weird
			// behaviour when returning to this page via the back button (updated DOM in the page cache)
			window.addEventListener('beforeunload', this.destroyTop10Trailers);
		}

		await this.loadPlugins();

		this.element.addEventListener('mouseenter', this.onMouseEnter, {
			passive: true,
		});
		this.element.addEventListener('mouseleave', this.onMouseLeave, {
			passive: true,
		});
	}

	removeEventListeners() {
		this.element.removeEventListener('mouseenter', this.onMouseEnter, {
			passive: true,
		});
		this.element.removeEventListener('mouseleave', this.onMouseLeave, {
			passive: true,
		});
	}

	async loadPlugins() {
		if (this.top10Trailers.length) {
			await Promise.all(this.promises);
		}
	}

	resizeHandler() {
		this.removeEventListeners();
		this.destroyTop10Trailers();

		if (browsersniffing.detectMobileOS() || isMobile()) {
			if (!document.documentElement.classList.contains(this.options.isMobile)) {
				document.documentElement.classList.add(this.options.isMobile);
			}

			return;
		}

		document.documentElement.classList.remove(this.options.isMobile);
		this.start();
	}

	onMouseEnter() {
		this.mouseEnterTimer = setTimeout(() => {
			this.showCard();
		}, this.delay);
	}

	onMouseLeave() {
		clearTimeout(this.mouseEnterTimer);
		this.hideCard();
	}

	showCard() {
		this.element.classList.add(this.options.isActive);
		this.card = this.element.querySelector(this.options.card);
		this.card.inert = false;
		this.card.querySelectorAll('a, button').forEach((link) => {
			link.removeAttribute('tabindex');
		});

		this.card.removeAttribute('aria-hidden');

		// not every card has a trailer
		if (this.element.querySelector(this.options.videoPlayerClass)) {
			// if it does, play the trailer
			this.playTop10Trailer();
		}

		this.createFocusTrap();
		this.firstFocusableElement?.focus();
	}

	hideCard() {
		if (!this.card) return;
		this.element.classList.remove(this.options.isActive);
		this.card.inert = true;
		this.card.setAttribute('aria-hidden', 'true');
		this.card.querySelectorAll('a, button').forEach((link) => {
			link.setAttribute('tabindex', '-1');
		});

		this.removeFocusTrap();

		const obj = window.App.activeTrailers.find(
			({ element }) => element === this.card
		);

		if (!obj || !obj.instance) return;
		obj.instance.pause();
	}

	playTop10Trailer() {
		if (!this.card) return;

		// if there's already another trailer (e.g. top banner) active
		// pause that one
		const allOtherPlayers = window.App.activeTrailers.filter(
			({ element }) => element?.dataset.id !== this.card.dataset.id
		);

		allOtherPlayers.forEach((player) => {
			if (player.instance && player.instance.playing) player.instance.pause();
		});

		// if this instance already exists, resume playing
		const obj = window.App.activeTrailers.find(
			({ element }) => element?.dataset.id === this.card.dataset.id
		);

		if (obj) {
			obj.instance.setVolume(getTrailerVolumeSetting());
			obj.instance.play();
			return;
		}

		window.App.activeTrailers.push({
			element: this.card,
			instance: undefined,
			state: undefined,
		});

		this.loadPlayer();
	}

	loadPlayer() {
		const playerContainer = this.card.querySelector(
			this.options.videoPlayerClass
		);

		if (!playerContainer) {
			return;
		}

		const assetId = playerContainer.dataset.id;
		const player = new popcornPlayer.Player(playerContainer, {
			device: {
				name: 'browser',
			},
			containerAspectRatio: 16 / 9,
			controls: {
				language: this.contextData.uiLanguage,
				keyboardNavigation: 'focusOnly',
			},
		});

		const controlsParams = {
			posterImageUrl: playerContainer.dataset.poster,
		};

		if (!player) return;
		const obj =
			window.App.activeTrailers.find(
				({ element }) => element?.dataset.id === this.card.dataset.id
			) || {};

		obj.instance = player;

		const volumeButton = this.card.querySelector(
			'[js-element~="top10VolumeButton"]'
		);
		if (!getTrailerVolumeSetting()) {
			setButtonMutedState(volumeButton);
		} else {
			setButtonUnMutedState(volumeButton);
		}

		player.addEventListener(popcornPlayer.Events.LoadStatusChange, (event) => {
			if (event.to === popcornPlayer.LoadStatus.Loaded) {
				obj.state = 'loaded';
				this.card.classList.add('is-ready');
				const tracks = player.textTracks;
				const defaultTrack = tracks.find(
					(track) =>
						track.language === window.playerConfig.uiLanguage && !track.variant
				);
				if (defaultTrack) player.selectTextTrack(defaultTrack.id);
			}
		});

		player.addEventListener(
			popcornPlayer.Events.PlayheadStateChange,
			(event) => {
				if (event.to === popcornPlayer.PlayheadState.Playing) {
					this.card.classList.add('is-playing');
					this.card.classList.remove('is-ended');
					this.card.classList.remove('is-paused');
					this.reportTrailerPlaying();
				}

				if (event.to === popcornPlayer.PlayheadState.Paused) {
					this.card.classList.add('is-paused');
					this.card.classList.remove('is-playing');
					this.card.classList.remove('is-ended');
				}

				if (event.to === popcornPlayer.PlayheadState.Ended) {
					// remove classes OR trigger mouseleave event
					this.card.classList.remove('is-playing');
					this.card.classList.remove('is-paused');
					this.card.classList.add('is-ended');
				}
			}
		);

		volumeButton.addEventListener(
			'click',
			function () {
				player.setVolume(player.volume === 0 ? 1 : 0);
				toggleVolumeButtonState();
			},
			{ passive: true }
		);

		player.loadFromButter(
			{
				// butterParams
				env: this.contextData.environment,
				apiKey: this.contextData.apiKey,
				authToken: this.contextData.token,
				id: assetId,
				userId: this.contextData.accountId,
				unstable_userIdForAdvertising: false,
				zone: this.contextData.zone,
			},
			{
				// playerParams
				enforceAdRules: false,
				autoPlay: true,
				allowMutedAutoPlay: true, // for Safari
				volume: getTrailerVolumeSetting(),
				controls: controlsParams,
				tracking: {
					gtm: {
						dataLayerName: 'dataLayer',
					},
				},
			}
		);
	}

	destroyTop10Trailers() {
		const top10Players = window.App.activeTrailers?.filter(
			({ element }) => element?.tagName === 'X-TOP10__CARD'
		);

		top10Players.forEach((player) => {
			const i = window.App.activeTrailers.indexOf(player);
			window.App.activeTrailers.splice(i, 1);
			player.instance.destroy();
			player.element = null;
			player = {};
		});
	}

	reportTrailerPlaying() {
		let { listData } = generateListData(
			this.element.closest('[swimlane-type="top10"]').dataset.tracking
		);
		const item = this.element.querySelector('[js-element~="tracking"]');
		let itemData = generateItemData(item.dataset.tracking);
		listData.asset_position = itemData.position;

		window.dataLayer.push({
			asset_id: itemData.assetId,
			event: 'trailer_started',
			list: listData,
		});
	}

	createFocusTrap() {
		const focusableElements = this.card.querySelectorAll(
			'[js-element~="top10Action"]'
		);
		this.firstFocusableElement = focusableElements[0];
		this.lastFocusableElement = focusableElements[focusableElements.length - 1];

		this.card.addEventListener('keydown', this.keydownHandler);
	}

	removeFocusTrap() {
		this.card.removeEventListener('keydown', this.keydownHandler);
	}

	top10KeydownHandler(event) {
		if (
			event.key === 'Tab' &&
			event.shiftKey &&
			document.activeElement === this.firstFocusableElement
		) {
			this.lastFocusableElement.focus();
			event.preventDefault();
		} else if (
			event.key === 'Tab' &&
			document.activeElement === this.lastFocusableElement
		) {
			this.firstFocusableElement.focus();
			event.preventDefault();
		}

		// WCAG SC 3.2.1: close card on Escape key
		if (event.key === 'Escape') {
			this.hideCard();
			this.element.querySelector('a').focus({ preventScroll: true });
		}
	}
}
