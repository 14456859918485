export default class Backdrop {
	constructor(config) {
		this.config = config;
		this.backdrop = this.createBackdrop();
	}

	createBackdrop() {
		const backdrop = document.createElement('x-backdrop');
		this.config?.classNames?.forEach((cls) => {
			backdrop.classList.add(cls);
		});
		this.config?.attributes?.forEach((attr) => {
			backdrop.setAttribute(attr.name, attr.value);
		});
		backdrop.setAttribute('aria-hidden', 'true');
		document.body.appendChild(backdrop);

		return backdrop;
	}

	getElement() {
		return this.backdrop;
	}

	removeBackdrop() {
		if (this.backdrop) {
			this.backdrop.remove();
			this.backdrop = null; // garbage collection
		}
	}
}
