export default class A11yAlert {
	constructor(message) {
		if (!message) throw new Error('No alert message provided');
		this.message = message;
		this.id = 'lfvpA11yAlert';

		this.createAlert();
	}

	createAlert() {
		const alertNode = document.getElementById(this.id);

		if (!alertNode) return;

		// add a11y attributes
		alertNode.setAttribute('aria-live', 'assertive');
		alertNode.inert = false; // remove `inert` from Modal and/or EditableTeaser
		while (alertNode.firstChild) {
			alertNode.removeChild(alertNode.firstChild);
		}

		const textContent = document.createTextNode(this.message);
		alertNode.appendChild(textContent);
	}
}
