let isSticky = false;
let stickyHeader = null;

function init() {
	const header = document.querySelector('[js-module~="feedHeader"]');

	if (
		!header ||
		(!('IntersectionObserver' in window) &&
			!('IntersectionObserverEntry' in window) &&
			!('isIntersecting' in window.IntersectionObserverEntry.prototype))
	) {
		return;
	}

	stickyHeader = document.querySelector('[js-module~="feedStickyHeader"]');

	createObserver(header);
}

function createObserver(element) {
	const observer = new IntersectionObserver(onIntersection, {
		threshold: 0.5,
	});

	observer.observe(element);
}

function onIntersection(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting && isSticky) {
			stickyHeader.setAttribute('aria-hidden', 'true');
			entry.target.removeAttribute('aria-hidden');
			isSticky = false;
		} else if (!entry.isIntersecting && !isSticky) {
			stickyHeader.removeAttribute('aria-hidden');
			entry.target.setAttribute('aria-hidden', 'true');
			isSticky = true;
		}
	});
}

export default {
	init,
};
