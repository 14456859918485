import { debounce } from '../../utils/throttle/debounce';
import { isMobile } from '../../utils/breakpoints/breakpoints';

let primaryNav, mobileNav;

function init() {
	primaryNav = document.querySelector('[js-module~="primaryNav"]');
	mobileNav = document.querySelector('[js-module~="mobileNav"]');

	if (!!primaryNav && !!mobileNav) {
		setAriaOnActiveNavigation();

		window.addEventListener(
			'resize',
			debounce(setAriaOnActiveNavigation, 100),
			{ passive: true }
		);
	}
}

function setAriaOnActiveNavigation() {
	if (isMobile()) {
		mobileNav.removeAttribute('aria-hidden');
		primaryNav.setAttribute('aria-hidden', 'true');
	} else {
		mobileNav.setAttribute('aria-hidden', 'true');
		primaryNav.removeAttribute('aria-hidden');
	}
}

export default {
	init,
};
