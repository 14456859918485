import Modal from '../../classes/modal/modal';
import Dialog from '../../classes/dialog/dialog';
import { isAlreadyModalShowing } from '../../utils/checkQueue/checkQueue';

window.dataLayer = window.dataLayer || [];
let observer, marketingTopOfScreenTrackingData, marketingBillboardTrackingData;

/*
	Marketing shizzle : TopOfScreen, popups, toasts, billboard, ...
	Emergency notifs (toasts) are also considered to be a "marketing" tool
	These toasts are not `assertive` and do not block the user from interacting
	with the page and that's why we use Dialog instead of Alert
*/

function init() {
	const mktToasttemplate = document.getElementById('marketingToastTemplate');
	if (mktToasttemplate) {
		// a mkt Toast and a mkt Modal can't coexist but there can be other modals
		// on the page, so we need to check if there are any modals active
		if (!isAlreadyModalShowing()) {
			showMarketingToast();
		} else {
			// if there were modals active, check again
			window.addEventListener('modalClosed', showMarketingToast, {
				passive: true,
			});
		}
	}

	// the modal/dialog marketing message (popup)
	const mktModalTemplate = document.getElementById('marketingModalTemplate');
	const modalHtml = mktModalTemplate?.content.cloneNode(true);

	if (modalHtml) {
		const trackingData = JSON.parse(mktModalTemplate.dataset.tracking);
		new Modal(
			`mkt-${trackingData.id}`,
			{
				message: modalHtml,
			},
			{
				onOpen: () => initiateMktOverlayTracking(trackingData),
				onClose: () => sendMktOverlayOnClose(trackingData),
			}
		);
	}

	if (document.querySelector('[js-module~="marketingTopOfScreen"]')) {
		if ('IntersectionObserver' in window) {
			observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							sendMktTopOfScreenImpression(entry.target.dataset.tracking);
							observer.unobserve(entry.target);
						}
					});
				},
				{
					threshold: 0.0,
				}
			);

			observer.observe(
				document.querySelector('[js-module~="marketingTopOfScreen"]')
			);
		}

		const closeButton = document.querySelector('[js-element~="mktClose"]');
		if (closeButton) {
			closeButton.addEventListener('click', mtkTopOfScreenClose, {
				once: true,
				passive: true,
			});
		}

		const cta = document.querySelector(
			'[js-module~="marketingTopOfScreen"] a.btn'
		);
		cta.addEventListener('click', sendMktTopOfScreenCtaClickEvent, {
			passive: true,
		});
	}

	if (document.querySelectorAll('[js-module~="marketingBillboard"]').length) {
		if ('IntersectionObserver' in window) {
			observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							sendMktBillboardImpression(entry.target.dataset.tracking);
							observer.unobserve(entry.target);
						}
					});
				},
				{
					threshold: 0.5,
				}
			);

			document
				.querySelectorAll('[js-module~="marketingBillboard"]')
				.forEach((billboard) => {
					observer.observe(billboard);
				});
		}

		const ctas = document.querySelectorAll(
			'[js-module~="marketingBillboard"] a'
		);

		ctas.forEach((cta) => {
			cta.addEventListener('click', sendMktBillboardCtaClickEvent, {
				passive: true,
			});
		});
	}
}

function showMarketingToast() {
	const marketingToast = document.getElementById('marketingToastTemplate');

	if (!marketingToast) return;

	const trackingDataObject = JSON.parse(marketingToast.dataset.tracking);

	new Dialog('marketingToast', marketingToast.content.cloneNode(true), {
		onOpen: () => initiateMktOverlayTracking(trackingDataObject),
		onClose: () => sendMktToastImpression(trackingDataObject.registerAction),
	});
}

export function initiateMktOverlayTracking(obj) {
	sendMktOverlayImpression(obj);
	const ctas = document.querySelectorAll('.modal .modal__actions .btn');
	if (!ctas.length) return;

	ctas.forEach((cta) => {
		cta.addEventListener('click', sendMktOverlayCtaClickEvent.bind(null, obj), {
			once: true,
			passive: true,
		});
	});
}

function sendMktOverlayCtaClickEvent(obj) {
	if (obj.registerAction && obj.registerAction !== 'null') {
		const action = obj.type === 'toast' ? 'VIEW' : 'CALL_TO_ACTION';
		sendImpressionToApi(obj.registerAction, action);
	}

	const cta = event?.currentTarget;
	const title = obj.type === 'popup' ? obj.name : obj.message;

	const campaign_id =
		obj.campaign_id === 'null' || !obj.campaign_id ? null : obj.campaign_id;

	window.dataLayer.push({
		campaign_id,
		event: 'product_click',
		list: {
			id: 'marketing_overlay',
			name: title,
			provider: 'default',
		},
		notification_cta: cta?.textContent.trim() ?? null,
		notification_id: obj.id,
		notification_title: title,
	});
}

function sendMktOverlayImpression(obj) {
	const title = obj.type === 'popup' ? obj.name : obj.message;
	const campaign_id =
		obj.campaign_id === 'null' || !obj.campaign_id ? null : obj.campaign_id;

	window.dataLayer.push({
		campaign_id,
		event: 'product_impression',
		list: {
			id: 'marketing_overlay',
			name: title,
			provider: 'default',
		},
		notification_id: obj.id,
		notification_title: title,
	});
}

export function sendMktOverlayOnClose(obj) {
	// only when the user uses clicks the close button
	// skip if it's global close (ESC, click outside, ...)
	if (event?.currentTarget?.className?.includes('modal__close')) {
		if (obj.registerAction !== 'null' && obj.registerAction) {
			sendImpressionToApi(obj.registerAction);
		}
	}

	const title = obj.type === 'popup' ? obj.name : obj.message;
	const campaign_id =
		obj.campaign_id === 'null' || !obj.campaign_id ? null : obj.campaign_id;

	window.dataLayer.push({
		campaign_id,
		event: 'product_dismiss',
		list: {
			id: 'marketing_overlay',
			name: title,
			provider: 'default',
		},
		notification_id: obj.id,
		notification_title: title,
	});
}

function sendMktToastImpression(endpoint) {
	if (endpoint) sendImpressionToApi(endpoint, 'VIEW');
}

function sendMktTopOfScreenCtaClickEvent() {
	window.dataLayer.push({
		campaign_id: marketingTopOfScreenTrackingData.campaign_id,
		event: 'product_click',
		list: {
			id: 'marketing_top_of_screen',
			name: marketingTopOfScreenTrackingData.title,
			provider: 'default',
		},
		notification_id: marketingTopOfScreenTrackingData.id,
		notification_title: marketingTopOfScreenTrackingData.title,
	});
}

function mtkTopOfScreenClose() {
	const marketingTopOfScreen = document.querySelector(
		'[js-module~="marketingTopOfScreen"]'
	);

	marketingTopOfScreen.style.setProperty(
		'--_mkt-offset',
		marketingTopOfScreen.offsetHeight + 'px'
	);
	marketingTopOfScreen.dataset.state = 'animateOut';
	marketingTopOfScreen.setAttribute('aria-hidden', 'true');
	sendMktTopOfScreenCloseClickEvent();
	sendImpressionToApi(marketingTopOfScreenTrackingData.registerAction);
}

function sendMktTopOfScreenCloseClickEvent() {
	window.dataLayer.push({
		campaign_id: marketingTopOfScreenTrackingData.campaign_id,
		event: 'product_dismiss',
		list: {
			id: 'marketing_top_of_screen',
			name: marketingTopOfScreenTrackingData.title,
			provider: 'default',
		},
		notification_id: marketingTopOfScreenTrackingData.id,
		notification_title: marketingTopOfScreenTrackingData.title,
	});
}

function sendMktTopOfScreenImpression(data) {
	marketingTopOfScreenTrackingData = JSON.parse(data);
	window.dataLayer.push({
		campaign_id: marketingTopOfScreenTrackingData.campaign_id,
		event: 'product_impression',
		list: {
			id: 'marketing_top_of_screen',
			name: marketingTopOfScreenTrackingData.title,
			provider: 'default',
		},
		notification_id: marketingTopOfScreenTrackingData.id,
		notification_title: marketingTopOfScreenTrackingData.title,
	});
}

function sendMktBillboardImpression(data) {
	marketingBillboardTrackingData = JSON.parse(data);
	window.dataLayer.push({
		campaign_id: marketingBillboardTrackingData.campaign_id,
		event: 'product_impression',
		list: {
			id: 'marketing_billboard',
			name: marketingBillboardTrackingData.title,
			position: marketingBillboardTrackingData.position,
			provider: 'default',
		},
		notification_id: marketingBillboardTrackingData.id,
		notification_title: marketingBillboardTrackingData.title,
	});
}

function sendMktBillboardCtaClickEvent() {
	window.dataLayer.push({
		campaign_id: marketingBillboardTrackingData.campaign_id,
		event: 'product_click',
		list: {
			id: 'marketing_billboard',
			name: marketingBillboardTrackingData.title,
			provider: 'default',
		},
		notification_id: marketingBillboardTrackingData.id,
		notification_title: marketingBillboardTrackingData.title,
	});
}

async function sendImpressionToApi(endpoint, action) {
	if (!action) action = 'CLOSE';

	await fetch(endpoint, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'x-csrf-token': window.App.csrfToken,
			'X-Requested-With': 'XMLHttpRequest',
		},
		body: JSON.stringify({
			action: action,
		}),
		keepalive: true,
	});
}

export default {
	init,
};
