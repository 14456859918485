import Modal from '../../classes/modal/modal';

function init() {
	const loginButtons = document.querySelectorAll(
		'[js-module~="loginRedirect"]'
	);
	const loginModalButtons = document.querySelectorAll(
		'[js-module~="loginModal"]'
	);

	loginButtons.forEach((loginButton) => {
		loginButton.addEventListener('click', setRedirectCookie, false);
	});

	loginModalButtons.forEach((button) => {
		button.addEventListener('click', (e) => {
			showLoginModal();
			e.preventDefault();
		});
	});
}

function setRedirectCookie(e) {
	const redirectUrl =
		e.currentTarget.dataset.redirectUrl || window.location.href;

	document.cookie = `lfvp_auth.redirect_uri=${redirectUrl};expires=0; path=/`;
}

function showLoginModal() {
	const html = document
		.getElementById('loginModalTemplate')
		?.content.cloneNode(true);

	if (!html) return;

	new Modal('Shortform', {
		message: html,
	});
}

export default {
	init,
};
