import Modal from '../../classes/modal/modal';
import {
	initiateMktOverlayTracking,
	sendMktOverlayOnClose,
} from '../marketing/marketing';

function init() {
	document
		.querySelectorAll('[js-module~="blockingOverlays"]')
		.forEach((trigger) => {
			trigger.addEventListener('click', (e) => {
				// do not select the (geo-) blocked teasers if the teaser.edit mode is active
				if (e.currentTarget.hasAttribute('aria-hidden')) return;

				const overlayId = e.currentTarget.getAttribute('data-overlay-id');
				/*
				 * The overlayId is used to find the correct overlay template in the DOM.
				 * Because the same template can occur more than once in the DOM (something with Ajax/Fetch), we use
				 * the data-template-id attribute (instead of id, which has to be unique)
				 * to identify the correct template.
				 */
				const overlay = document.querySelector(
					`[data-template-id="${overlayId}"]`
				);

				const html = overlay?.content.cloneNode(true);
				let alertDialog = false;
				let callbacks = {};
				let trigger = null;

				if (!html) return;

				// it's a popup (modal)
				if (overlay.dataset.overlayType === 'POPUP') {
					const trackingData = JSON.parse(overlay.dataset.tracking);
					if (trackingData) {
						callbacks = {
							onOpen: initiateMktOverlayTracking.bind(null, trackingData),
							onClose: sendMktOverlayOnClose.bind(null, trackingData),
						};
					}
				} else {
					// it's a toast (alertdialog)
					alertDialog = true;
					trigger = e.currentTarget;
				}

				new Modal(
					`bo-${overlayId}`,
					{
						message: html,
						alertDialog,
						icon: null,
					},
					callbacks,
					null,
					trigger
				);

				e.preventDefault();
			});
		});
}

export default {
	init,
};
