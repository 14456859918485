/*
	Toggle: Toggle visibility of elements.
	E.g.: the read more/less button on a detail page.
*/

export default class Toggle {
	constructor(element, options = {}) {
		this.element = element;
		this.options = options;
		this.isOpen = false;
		this.panel = this.options.panel || this.element.nextElementSibling;
		this.id = null;

		this.start();
	}

	start() {
		this.id = this.generateRandomId();
		this.setAttributes();

		this.element.addEventListener('click', () => this.toggleHandler());
	}

	setAttributes() {
		this.setClosedState();
		this.element.setAttribute('aria-controls', this.id);
		this.panel.id = this.id;
	}

	generateRandomId() {
		return `t-${Date.now() * Math.trunc(Math.random() * 100)}`;
	}

	toggleHandler() {
		if (!this.isOpen) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	setOpenState() {
		this.element.setAttribute('aria-expanded', 'true');
		this.element.textContent = window.i18n.read.less;
		this.panel.removeAttribute('aria-hidden');
		this.isOpen = true;
	}

	setClosedState() {
		this.element.setAttribute('aria-expanded', 'false');
		this.element.textContent = window.i18n.read.more;
		this.panel.setAttribute('aria-hidden', 'true');
		this.isOpen = false;
	}

	destroyToggle() {
		this.element.removeEventListener('click', () => this.toggleHandler());
		this.element.removeAttribute('aria-controls');
		this.element.removeAttribute('aria-expanded');
		this.panel.removeAttribute('aria-hidden');
		this.isOpen = false;
		this.panel.id = null;
	}
}
