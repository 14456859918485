import Modal from '../../classes/modal/modal';
import cookiez from '../../utils/cookies/cookies';

/* Privay Gate & purposes (TFC2)
	The DPG Media Privacy Gate (Sourcepoint) & RTL Play (Didomi)
	* functional consent = custom consent for the site to enable its basic functionality
	* purpose 1 = IAB, storage & access
	* purpose 4 = IAB, personalised & targeted ads

	docs for Didomi: https://developers.didomi.io/cmp/web-sdk
*/

let privacyGateProvider;

function init() {
	privacyGateProvider = window.App?.privacyGateProvider.toLowerCase();
	// if there are privacy gate links on the page, add event listeners to them
	const pgLinks = document.querySelectorAll('[js-module~="privacyGate"]');
	if (pgLinks.length) {
		pgLinks.forEach((link) => {
			link.addEventListener('click', (e) => {
				openPrivacyModal();
				e.preventDefault();
			});
		});
	}

	addListeners();

	// get all the privacy consents if it's been longer than 2 hrs (7200000 ms)
	if (
		!window.sessionStorage.getItem('lfvp_pg_consentstring') ||
		!window.sessionStorage.getItem('lfvp_pg_lastupdated') ||
		Date.now() - window.sessionStorage.getItem('lfvp_pg_lastupdated') > 7200000
	) {
		getFunctionalConsent();
		getPurpose1();
		getPurpose4();
		setPrivacyLastUpdated();

		// remove obsolete cookies - we've replaced them with sessionStorage
		if (cookiez.get('lfvp_pg_purpose1')) {
			cookiez.remove('lfvp_pg_purpose1');
		}

		if (cookiez.get('lfvp_pg_purpose4')) {
			cookiez.remove('lfvp_pg_purpose4');
		}

		if (cookiez.get('lfvp_pg_consentstring')) {
			cookiez.remove('lfvp_pg_consentstring');
		}
	}
}

function isSourcePoint() {
	return privacyGateProvider === 'sourcepoint';
	// is it SourcePoint or Didomi?
}

function addListeners() {
	if (isSourcePoint()) {
		window._privacy.push(['error', privacyGateErrorFallback]);
		window._privacy.push(['consentupdated', onConsentUpdated]);
	} else {
		window.didomiOnReady = window.didomiOnReady || [];
		window.didomiEventListeners = window.didomiEventListeners || [];

		window.didomiEventListeners.push({
			event: 'consent.changed',
			listener: function () {
				onConsentUpdated();
			},
		});

		window.didomiEventListeners.push({
			event: 'api.error',
			listener: function (obj) {
				privacyGateErrorFallback(obj);
			},
		});
	}
}

function openPrivacyModal() {
	if (isSourcePoint()) {
		window._privacy.openModal();
	} else {
		window.Didomi.preferences.show();
	}
}

function getFunctionalConsent() {
	if (isSourcePoint()) {
		window._privacy.push(['functional', getConsentString, onConsentError]);
	} else {
		window.didomiOnReady.push(function () {
			getConsentString();
		});
	}
}

function getPurpose1() {
	if (isSourcePoint()) {
		window._privacy.push([
			'1',
			storePurpose1InSessionStorage,
			removePurpose1FromSessionStorage,
		]);
	} else {
		window.didomiOnReady.push(function (Didomi) {
			if (Didomi.getUserConsentStatusForPurpose('cookies')) {
				storePurpose1InSessionStorage();
			}
			if (!Didomi.getUserConsentStatusForPurpose('cookies')) {
				removePurpose1FromSessionStorage();
			}
		});
	}
}

function getPurpose4() {
	if (isSourcePoint()) {
		window._privacy.push([
			'4',
			storePurpose4InSessionStorage,
			removePurpose4FromSessionStorage,
		]);
	} else {
		window.didomiOnReady.push(function (Didomi) {
			if (Didomi.getUserConsentStatusForPurpose('select_personalized_ads')) {
				storePurpose4InSessionStorage();
			}

			if (!Didomi.getUserConsentStatusForPurpose('select_personalized_ads')) {
				removePurpose4FromSessionStorage();
			}
		});
	}
}

function storePurpose1InSessionStorage() {
	window.sessionStorage.setItem('lfvp_pg_purpose1', 'true');
}

function removePurpose1FromSessionStorage() {
	window.sessionStorage.removeItem('lfvp_pg_purpose1');
}

function storePurpose4InSessionStorage() {
	window.sessionStorage.setItem('lfvp_pg_purpose4', 'true');
}

function removePurpose4FromSessionStorage() {
	window.sessionStorage.removeItem('lfvp_pg_purpose4');
}

function privacyGateErrorFallback(obj) {
	console.error('LFVP: failure loading privacy gate', obj ? obj : '');
}

function getConsentString() {
	if (isSourcePoint()) {
		window._privacy.push([
			'onTcString',
			(tcString) => storeConsentStringInSesssionStorage(tcString),
		]);
	} else {
		window.__tcfapi('getTCData', null, function (result) {
			storeConsentStringInSesssionStorage(result.tcString);
		});
	}
}

function storeConsentStringInSesssionStorage(consentString) {
	window.sessionStorage.setItem('lfvp_pg_consentstring', consentString);
}

function setPrivacyLastUpdated() {
	window.sessionStorage.setItem('lfvp_pg_lastupdated', Date.now());
}

function onConsentUpdated() {
	getFunctionalConsent();
	getPurpose1();
	getPurpose4();
	setPrivacyLastUpdated();
}

function onConsentError() {
	const html = document
		.getElementById('privacyGateModalTemplate')
		?.content.cloneNode(true);

	if (!html) return;

	const pgModal = new Modal('PrivacyConsent', {
		message: html,
	});

	const link = window.PrivacyConsent.querySelector(
		`[js-element~="privacyGateErrorLink"]`
	);

	privacyGateProvider = link.dataset.privacyProvider.toLowerCase();

	link.addEventListener('click', (e) => {
		e.preventDefault();
		pgModal.close();
		openPrivacyModal();
	});
}

export default {
	init,
};
