import Modal from '../modal/modal';
import A11yAlert from '../a11y/a11y-alert';
import Backdrop from '../backdrop/backdrop';

export default class EditableTeaserGroup {
	constructor(element, { onDelete } = {}) {
		this.element = element;
		this.forms = this.element.querySelectorAll(
			'[js-element~="teaserEditForm"]'
		);
		this.toggleButton = this.element.querySelector(
			'[js-element~="teaserEditToggle"]'
		);
		this.deleteButtons = this.element.querySelectorAll(
			'[js-element~="teaserEditDeleteButton"]'
		);
		this.body = document.body;
		this.EDITING_CLASS = 'is-editing-teasers';
		this.teasers = this.element.querySelectorAll('[js-module~="teaser"]');
		this.container = null;
		this.totalItems = null;
		this.onDelete = onDelete;
		this.isEmpty = false;
		this.removedTeaserId = null;
		this.removedTeaserForm = null;
		this.backdrop = null;
		// for the focus trap
		this.keyDownHandler = this.editableTeasersKeyDownHandler.bind(this);
		this.firstFocusableEl = null;
		this.lastFocusableEl = null;
		this.createEditableTeaserInstance();
	}

	createEditableTeaserInstance() {
		this.forms.forEach((form) => {
			form.addEventListener('submit', (e) => {
				this.onSubmitHandler(e);
			});
		});

		this.toggleButton.addEventListener(
			'click',
			() => {
				this.toggleClickHandler();
			},
			{
				passive: true,
			}
		);
	}

	onSubmitHandler(event) {
		event.preventDefault();
		this.sendAjaxRequestToMyList(event.currentTarget);
	}

	removeTeaserFromList(teaser) {
		this.container = teaser.closest('[js-element~="teaserEditContainer"]');
		this.totalItems = this.container.children.length;

		teaser.addEventListener(
			'transitionend',
			(e) => {
				this.deleteTeaser(e);
			},
			{
				passive: true,
				once: true,
			}
		);
		teaser.classList.add('is-fading');
	}

	deleteTeaser(e) {
		e.currentTarget.parentElement.blur(); // remove the focus
		e.currentTarget.parentElement.remove(); // delete the item
		this.totalItems--;
		// add successfull feedback for screen readers
		this.giveSuccessfulA11yFeedback();

		this.container.children[this.totalItems]
			?.querySelector('form button')
			.focus(); // focus the next item

		if (this.totalItems === 0) {
			this.body.classList.remove(this.EDITING_CLASS);
			this.removeActiveState();
			this.emptyState();
		}

		if (typeof this.onDelete === 'function') {
			this.onDelete(this);
		}
	}

	emptyState() {
		this.isEmpty = true;
	}

	showError(el) {
		// remove the spinner
		el.classList.remove('is-in-progress');
		// show the alert
		if (!window.App.alertQueue)
			new Modal('lfvpAlertDialog', {
				message: window.i18n.alertMyListError,
				alertDialog: true,
			});
	}

	toggleClickHandler() {
		this.toggleHighlight();
	}

	toggleHighlight() {
		if (this.body.classList.contains(this.EDITING_CLASS)) {
			this.removeActiveState();
		} else {
			this.setActiveState();
		}
	}

	removeActiveState() {
		this.element.classList.remove('is-editing');
		this.body.classList.remove(this.EDITING_CLASS);
		// get all links and buttons that are not delete buttons, toggle button or teasers
		this.element.querySelectorAll('a, button').forEach((el) => {
			if (
				Array.from(this.deleteButtons).indexOf(el) === -1 &&
				el !== this.toggleButton &&
				Array.from(this.teasers).indexOf(el) === -1
			) {
				if (el.getAttribute('aria-selected') !== 'false') {
					el.removeAttribute('tabindex');
				}
				if (el.tagName === 'BUTTON') {
					el.disabled = false;
				}
			}
		});
		this.element
			.querySelector('[js-element~="tabPanel"]:not([aria-hidden])')
			?.setAttribute('tabindex', '0');

		this.toggleButton.classList.remove('btn--primary');
		this.toggleButton.classList.add('btn--secondary');
		this.toggleButton.textContent = window.i18n.edit;
		this.teasers.forEach((tsr) => {
			tsr.removeAttribute('aria-hidden');
			tsr.removeAttribute('tabindex');
		});
		[...document.body.children].forEach((child) => {
			if (child.tagName !== 'SCRIPT' && child.tagName !== 'MAIN') {
				child.inert = false;
			}
		});
		if (document.querySelector('main')) {
			[...document.querySelector('main').children].forEach((child) => {
				child.inert = false;
			});
		}
		this.backdrop?.removeBackdrop();
		this.removeKeydownListeners();
		this.firstFocusableEl = null;
		this.lastFocusableEl = null;
	}

	setActiveState() {
		this.element.classList.add('is-editing');
		this.body.classList.add(this.EDITING_CLASS);
		// get all links and buttons that are not delete buttons, toggle button or teasers
		this.element.querySelectorAll('button, a').forEach((el) => {
			if (
				Array.from(this.deleteButtons).indexOf(el) === -1 &&
				el !== this.toggleButton &&
				Array.from(this.teasers).indexOf(el) === -1
			) {
				if (el.getAttribute('aria-selected') !== 'false') {
					el.setAttribute('tabindex', '-1');
				}
				if (el.tagName === 'BUTTON') {
					el.disabled = true;
				}
			}
		});
		this.element
			.querySelector('[js-element~="tabPanel"]:not([aria-hidden])')
			?.setAttribute('tabindex', '-1');

		this.toggleButton.classList.add('btn--primary');
		this.toggleButton.classList.remove('btn--secondary');
		this.toggleButton.textContent = window.i18n.ready;
		this.teasers.forEach((tsr) => {
			tsr.setAttribute('aria-hidden', 'true');
			tsr.setAttribute('tabindex', '-1');
		});
		[...document.body.children].forEach((child) => {
			if (child.tagName !== 'SCRIPT' && child.tagName !== 'MAIN') {
				child.inert = true;
			}
		});
		if (document.querySelector('main')) {
			[...document.querySelector('main').children].forEach((child) => {
				if (this.element !== child && this.element.parentElement !== child) {
					child.inert = true;
				}
			});
		}
		// should not get the `inert` attribute
		this.createBackdrop();

		this.addKeydownListeners();
		this.trapFocus();
	}

	giveSuccessfulA11yFeedback() {
		new A11yAlert(window.i18n.removedFromMyList);
	}

	createBackdrop() {
		this.backdrop = new Backdrop({
			attributes: [{ name: 'js-element', value: 'teaserEditBackdrop' }],
		});
		document
			.querySelector('[js-element~="teaserEditBackdrop"]')
			.addEventListener('click', (e) => this.globalClose(e), {
				passive: true,
				once: true,
			});
	}

	addKeydownListeners() {
		this.element.addEventListener('keydown', this.keyDownHandler);
	}

	removeKeydownListeners() {
		this.element.removeEventListener('keydown', this.keyDownHandler);
	}

	editableTeasersKeyDownHandler(e) {
		// a11y - usability: close backdrops on ESC
		if (e.key === 'Escape' || e.key === 'Esc') {
			this.globalClose();
		}
		// focus trap
		if (
			e.key === 'Tab' &&
			e.shiftKey &&
			document.activeElement === this.firstFocusableEl
		) {
			this.lastFocusableEl.focus();

			e.preventDefault();
		} else if (
			e.key === 'Tab' &&
			document.activeElement === this.lastFocusableEl
		) {
			this.firstFocusableEl.focus();

			e.preventDefault();
		}
	}

	trapFocus() {
		const focusableElements = this.element.querySelectorAll(
			'[js-element~="teaserEditDeleteButton"], [js-element~="teaserEditToggle"]'
		);

		// Save first and last focusable elements
		this.firstFocusableEl = focusableElements[0];
		this.lastFocusableEl = focusableElements[focusableElements.length - 1];
	}

	globalClose() {
		this.removeActiveState();
	}

	async sendAjaxRequestToMyList(frm) {
		// we show a spinner
		frm.classList.add('is-in-progress');

		const response = await fetch(frm.action, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				'x-csrf-token': window.App?.csrfToken,
			},
		});

		if (!response.ok && response.status !== 404) {
			this.showError(frm);
			return;
		}
		this.removedTeaserForm = frm;
		this.removedTeaserId = frm.dataset.id;
		this.removeTeaserFromList(frm.closest('x-teaser'));
	}
}
