/* globals popcornPlayer */

/*
	This is Popcorn player v4
	docs: https://player-dev.popcorn.persgroep.cloud/v4/main/docs/index.html
*/
import A11yAlert from '../../classes/a11y/a11y-alert';
import AutoPlay from '../../classes/autoPlay/autoPlay';
import cookieFunctions from '../../utils/cookies/cookies';
import { fetchDMOI } from '../../utils/fetchDmoi/fetchDmoi';
import {
	abortPutPlayerPosition,
	putPlayerPosition,
} from '../../utils/putPlayerPostition/putPlayerPosition';
import { scrollIntoView } from '../../utils/scroll/scroll';
import mylist from '../my-list/my-list';
import progress from '../progress/progress';
import tracking from '../tracking/tracking.logic';

export const VOLUME_COOKIE = 'lfvp_volume-setting';
let defaultConsentString;
let playerModule;
let minified;
let contextData;
let hasFetchedRecommendations;
let playerData;
let timerId;
let idleTimerId;
let lastPosition;
let productHasDmoi;

async function init() {
	minified = false;
	hasFetchedRecommendations = false;
	defaultConsentString = window.App?.minimalConsentString;
	contextData = window.playerConfig;
	playerData = window.App?.playerData;
	playerModule = document.querySelector('[js-module~="player"]');
	productHasDmoi =
		window.App?.dmoiConfig?.apiKey !== 'null' && window.App?.dmoiConfig?.apiKey
			? true
			: false;
	if (!playerModule) return;

	const playerContainer = playerModule.querySelector(
		'[js-element~="playerContainer"]'
	);

	const promises = [
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.Heartbeat),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.GTM),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.CIM),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.Mux),
		popcornPlayer.loadScript(popcornPlayer.ScriptName.GemiusPlayer),
	];

	if (playerContainer.dataset.type === 'live') {
		// CSAI on Live (only for RTL Play now)
		if (playerContainer.dataset.csaionlive === 'true') {
			promises.push(
				popcornPlayer.loadScript(popcornPlayer.ScriptName.FreeWheel)
			);
		}
		setupLivePlayerEPG();
	}

	if (playerContainer.dataset.type === 'vod') {
		promises.push(popcornPlayer.loadScript(popcornPlayer.ScriptName.FreeWheel));
	}

	await Promise.all(promises);

	loadPlayer(playerContainer, playerData);
}

async function loadPlayer(container, data) {
	const player = new popcornPlayer.Player(container, {
		device: {
			name: 'browser',
		},
		controls: {
			language: contextData.uiLanguage,
			keyboardNavigation: true,
		},
	});

	let volume = 1;
	if (window.sessionStorage.getItem(VOLUME_COOKIE)) {
		volume = parseFloat(window.sessionStorage.getItem(VOLUME_COOKIE));
	}

	const userConsentString =
		window.sessionStorage.getItem('lfvp_pg_consentstring') ??
		defaultConsentString;

	const controlsParams = {
		posterImageUrl: data.poster,
	};

	if (data.next) {
		controlsParams.nextEpisode = {
			title: data.next.title,
			subtitle: data.next.subtitle,
			description: data.next.description,
			imageUrl: data.next.imageUrl,
		};
		controlsParams.onNextEpisode = () => {
			window.location.replace(data.next.href);
		};
	}

	setupDefaultSubtitle(player);
	setupPositionTracking(player, data);
	setupOverlay(player);
	setupNextRecommendation(player, data);

	player.loadFromButter(
		{
			// butterParams
			apiKey: contextData.apiKey,
			id: data.assetId,
			authToken: contextData.token,
			env: contextData.environment,
			pageUrl: data.detailUrl,
			unstable_userIdForAdvertising: await getUserIdForAdvertising(),
			userConsentString,
			userId: contextData.accountId,
			zone: contextData.zone,
		},
		{
			// playerParams
			allowMutedAutoPlay: true, // for Safari
			autoPlay: true,
			controls: controlsParams,
			enforceAdRules: true,
			startTime: data.startPosition ? data.startPosition : undefined, // undefined or a value in seconds -> undefined = with preroll
			tracking: {
				gtm: {
					dataLayerName: 'dataLayer',
				},
			},
			volume,
		}
	);
}

async function getUserIdForAdvertising() {
	// get purposes and consent from the sessionStorage
	// purpose 4 = Select personalised ads
	const consentAllowsPersonalisedAds =
		window.sessionStorage.getItem('lfvp_pg_purpose4');

	if (consentAllowsPersonalisedAds === 'false') {
		// Provide false to tell player not to take any id into account
		// and will not send userid to FreeWheel
		return false;
	}

	// dmoi is VTM GO (DPG Media) specific
	if (!productHasDmoi) {
		// We do not do anything special for advertising, `undefined` tells player
		// we don't have a different id for advertising.
		return undefined;
	}

	// we have DMOI
	const cookie = cookieFunctions.get('lfvp_dmoi');

	if (cookie?.length && cookie !== 'null') {
		return cookie;
	}

	// no dmoi, try cxense (although it's been replaced with DMOI)
	const cxenseUuid = window.localStorage.getItem('_cX_G');
	const dmoiUserId = await fetchDMOI(cxenseUuid);
	// set the lfvp_dmoi cookie so we no longer need cxense
	if (dmoiUserId) cookieFunctions.set('lfvp_dmoi', dmoiUserId, 1, '/');
	return dmoiUserId;
}

function setupDefaultSubtitle(player) {
	player.addEventListener(popcornPlayer.Events.LoadStatusChange, (event) => {
		if (event.to === popcornPlayer.LoadStatus.Loaded) {
			const tracks = player.textTracks;
			const defaultTrack = tracks.find(
				(track) => track.language === contextData.uiLanguage && !track.variant
			);
			if (defaultTrack) player.selectTextTrack(defaultTrack.id);
		}
	});
}

function setupPositionTracking(player) {
	if (!contextData.updatePlayerPositionUrl) return;

	const sendPosition = async (obj) => {
		clearTimeout(timerId);

		const position = parseInt(player.contentTime, 10);
		if (!player.adBreak && lastPosition !== position) {
			await putPlayerPosition(player, position, contextData);
			lastPosition = position;
		}

		// only send position once, no need to keep sending it
		if (obj?.once) return;

		timerId = setTimeout(() => {
			sendPosition();
		}, 120_000); // two minutes
	};

	player.addEventListener(popcornPlayer.Events.PlayheadStateChange, (event) => {
		clearTimeout(idleTimerId);

		switch (event.to) {
			case popcornPlayer.PlayheadState.Playing:
				sendPosition();
				break;
			case popcornPlayer.PlayheadState.Paused:
			case popcornPlayer.PlayheadState.Ended:
				// only send position once, no need to keep sending it
				sendPosition({ once: true });
				// if the player is paused or ended, we want to destroy it after three hours
				// this is to prevent MUX errors (DRM license expired) when the player is paused for a long time
				idleTimerId = setTimeout(() => {
					player.destroy();
					abortPutPlayerPosition();
					showRefreshMessage();
				}, 3 * 60 * 60_000); // three hours (chosen arbitrary)
				break;
		}
	});

	player.addEventListener(popcornPlayer.Events.AdBreakEnded, sendPosition); // over an adbreak seeked?
	window.addEventListener('pagehide', sendPosition, { once: true }); // tab closed or navigated away
}

function setupOverlay(player) {
	const element = player.container.querySelector(
		'[js-element~="playerOverlay"]'
	);
	if (!element) return;

	const closeButton = element.querySelector('[js-element~="playerClose"]');
	closeButton?.addEventListener('click', (event) => {
		if (
			window.history.length > 1 &&
			document.referrer.indexOf(window.location.origin) === 0 &&
			document.referrer.indexOf(window.i18n.player.urlPath) === -1
		) {
			window.history.back();
			event.preventDefault();
		}
	});

	const onUpdate = () => {
		const uiIsVisible =
			player.playheadState !== popcornPlayer.PlayheadState.Playing ||
			player.controls.isActive;

		element.classList.toggle('ui-is-visible', uiIsVisible);
		playerModule.classList.toggle('has-overlay', uiIsVisible);
	};

	player.addUniversalEventListener(onUpdate);
	player.controls.addUniversalEventListener(onUpdate);
}

function setupNextRecommendation(player, data) {
	// only when there's a next reco url (not on the live)
	// nextPlayable will be null
	if (data.recoUrl && !data.next?.href) {
		player.addEventListener(popcornPlayer.Events.MarkersChange, (e) => {
			e.markers.find((marker) => {
				if (
					marker.isActive === true &&
					marker.type === popcornPlayer.MarkerType.EndCredits
				) {
					// if we seeked over an adbreak, we don't want to show the next recommendation unitl tha adbreak is over
					if (player.adBreak) {
						player.addEventListener(popcornPlayer.Events.AdBreakEnded, () => {
							showNextRecommentadion(player, data);
						});
						return;
					}
					showNextRecommentadion(player, data);
				}
			});
		});

		player.addEventListener(popcornPlayer.Events.PlayheadStateChange, (e) => {
			if (e.to === popcornPlayer.PlayheadState.Ended) {
				// if we seeked over an adbreak, we don't want to show the next recommendation unitl tha adbreak is over
				if (player.adBreak) {
					player.addEventListener(popcornPlayer.Events.AdBreakEnded, () => {
						showNextRecommentadion(player, data);
					});
					return;
				}
				showNextRecommentadion(player, data);
			}
		});
	}
}

function showNextRecommentadion(player, data) {
	if (!minified && !hasFetchedRecommendations) {
		fetchRecommendations(player, data);
	}
	if (!minified && hasFetchedRecommendations) {
		minifyPlayer(player);
	}
}

async function fetchRecommendations(player, data) {
	const response = await fetch(window.App.playerData.recoUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'text/xml',
			'X-Requested-With': 'XMLHttpRequest',
		},
	});

	// we get a resp.status = 204 if there's no recommendation
	if (response.status === 204 || !response.ok) {
		// send user to the detail page
		window.location.href = data.detailUrl;
		return;
	}

	// if there is a recommendation, the resp = html
	const content = await response.text();
	playerModule.insertAdjacentHTML('afterbegin', content);

	let autoPlayButton = document.querySelector('[js-module~="autoPlay"]');
	if (autoPlayButton) {
		new A11yAlert(
			window.i18n.autoPlayA11y(autoPlayButton.dataset.nextItemTitle)
		);
		new AutoPlay(autoPlayButton);
	}

	minifyPlayer(player);

	const reco = document.querySelector('[lfvp-tracking="nextReco"]');
	tracking.init(reco);
	document
		.querySelector('[js-element~="playerClose"]')
		.setAttribute('href', window.App.playerData.detailUrl);
	hasFetchedRecommendations = true;
}

function minifyPlayer(player) {
	if (minified) return;

	if (player.isFullscreen) {
		document.exitFullscreen().then(() => {
			addMinifyState();
		});
	} else {
		addMinifyState();
	}

	player.container.addEventListener('click', magnifyPlayer, {
		passive: true,
	});
}

function addMinifyState() {
	if (!playerModule.classList.contains('is-minified')) {
		playerModule.classList.add('is-minified');
		// enable the Mijn Lijst functionality
		mylist.init();

		minified = true;
	}
}

function magnifyPlayer() {
	playerModule.classList.remove('is-minified');
	minified = false;
}

function setupLivePlayerEPG() {
	// cloning the Live EPG because we need control over it with
	// CSS when showing it on the mobile breakpoint (portrait)
	const overlay = document.querySelector('[js-element~="playerOverlay"]');
	const liveEpg = overlay.cloneNode(true);
	liveEpg.removeAttribute('js-element');
	document.querySelector('[js-module~="player"]').appendChild(liveEpg);
	// activate the auto progress bar indicators on the original and cloned EPG
	progress.init();
	// activate the scroll to active channel on the original and cloned EPG
	document
		.querySelectorAll('[js-module~="liveScroller"]')
		.forEach((scroller) => {
			const activeChannel = scroller.querySelector('[aria-current="true"]');
			scrollIntoView(scroller, activeChannel);
		});
}

function showRefreshMessage() {
	const messageContainer = document.createElement('div');

	messageContainer.classList.add('lfvp-player__refresh-container');
	messageContainer.innerHTML = `<p class="lfvp-player__refresh-message">${window.i18n.player.refresh}</p>
		<a href="${window.location.href}" class="btn btn--primary">${window.i18n.refresh}</a>`;
	playerModule.appendChild(messageContainer);
}

export default {
	init,
};
