/*
 * Dialog class
 * Use this for dialogs that are NOT modals.
 * Dialogs are typically role="dialog" + aria-modal="false" and don't use a
 * backdrop to separate it from the rest of the content. However, they do lay
 * on top of the content but allow to interact with the content behind it.
 * (e.g. the Marketing Toast)
 * For modals, use the Modal class. ➡️ /classes/modal/modal.js
 */

export default class Dialog {
	constructor(
		id,
		template, // HTML <template> for the dialog
		{ onClose, onOpen } = {},
		modifiers,
		focussedElement
	) {
		this.template = template;
		if (!this.template) throw Error('No content provided for the dialog');

		this.dialog = null;
		this.id = id ?? `dialog-${Date.now()}`;
		this.supportsNativeDialogElement = false;
		this.modifiers = modifiers; // array of additional class names
		// Default callbacks, can be overwritten
		this.onClose = onClose;
		this.onOpen = onOpen;
		this.dialogHasFocus = false;
		this.focussedElement = focussedElement;
		this.closeButton = null;

		this.checkDialogElementCompatibility();
		this.createDialog();
		this.addEventListeners();
	}

	checkDialogElementCompatibility() {
		if (typeof HTMLDialogElement === 'function') {
			this.supportsNativeDialogElement = true;
		}
	}

	createDialog() {
		const dialog = this.supportsNativeDialogElement
			? document.createElement('dialog')
			: document.createElement('div');
		dialog.id = this.id;
		dialog.classList.add('alert', 'alert--toast');
		if (!this.supportsNativeDialogElement)
			dialog.setAttribute('role', 'dialog');
		dialog.setAttribute('aria-labelledby', `${this.id}Message`);
		dialog.setAttribute('aria-modal', 'false'); // important for Toasts
		dialog.setAttribute('data-nosnippet', 'true');
		dialog.appendChild(this.template);

		this.closeButton = dialog.querySelector('[js-element~="alertClose"]');

		this.dialog = dialog;
		document.body.appendChild(this.dialog);

		this.renderDialog();
	}

	renderDialog() {
		document.activeElement.blur();
		this.dialog.querySelector('[js-element~="alertClose"]').focus(); // as ARIA requires
		this.dialogHasFocus = true;

		if (
			window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
			window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true
		) {
			this.showDialog();
		} else {
			window.requestAnimationFrame(() => this.showDialog());
		}
	}

	showDialog(onOpen = this.onOpen) {
		if (this.supportsNativeDialogElement) {
			this.dialog.show(); // native <dialog> show method
		} else {
			this.dialog.setAttribute('open', '');
		}

		if (typeof onOpen === 'function') {
			onOpen();
		}
	}

	hideDialog(onClose = this.onClose) {
		if (this.supportsNativeDialogElement) {
			this.dialog.close(); // native <dialog> close method
		} else {
			this.dialog.removeAttribute('open');
		}

		if (this.focussedElement) {
			this.focussedElement.focus();
		} else {
			document.body.focus();
		}

		if (typeof onClose === 'function') {
			onClose();
		}
		this.removeEventListeners();
		this.dialog.remove();
		this.closeButton = null; // garbage collection
		this.dialog = null; // garbage collection
	}

	addEventListeners() {
		document.addEventListener('keydown', (e) => this.handleKeydown(e));

		this.closeButton.addEventListener('click', () => this.hideDialog());
	}

	removeEventListeners() {
		document.removeEventListener('keydown', (e) => this.handleKeydown(e));

		this.closeButton.removeEventListener('click', () => this.hideDialog());
	}

	handleKeydown(e) {
		if (!this.dialog) return;
		// ARIA requirement: use a "global" keyboard shortcut to switch between the
		// toast and the rest of the content
		if (e.key === 'F6' && e.ctrlKey) {
			if (this.dialogHasFocus) {
				this.dialog.querySelector('[js-element~="alertClose"]').blur();
				document.querySelector('.skippy').focus();
				this.dialogHasFocus = false;
			} else {
				document.activeElement.blur();
				this.dialog.querySelector('[js-element~="alertClose"]').focus();
				this.dialogHasFocus = true;
			}
		}
	}

	remove() {
		this.dialog.remove();
		this.dialog = null;
	}
}
