import { isMobile } from '../../utils/breakpoints/breakpoints';
import { debounce } from '../../utils/throttle/debounce';
import Toggle from '../../classes/toggle/toggle';

let toggles = [];

function init() {
	window.addEventListener('resize', debounce(resizeHandler, 100), {
		passive: true,
	});

	if (isMobile()) {
		createToggles();
	}
}

function createToggles() {
	const toggleElements = document.querySelectorAll(
		'[js-module~="mediaToggle"]'
	);

	toggleElements.forEach((toggleElement) => {
		toggles.push(
			new Toggle(toggleElement, { panel: toggleElement.nextElementSibling })
		);
	});
}

function resizeHandler() {
	if (!isMobile()) {
		if (toggles.length) {
			toggles.forEach((toggle) => {
				toggle.destroyToggle();
			});
			toggles = [];
		}
	} else {
		if (!toggles.length) {
			createToggles();
		}
	}
}

export default {
	init,
};
